/* eslint-disable max-len */
import { requisicaoComSucesso } from "../../shared/util/utils";
import UsuarioService from "../usuario/usuarioService";
import usuarioClienteVinculoService from "../usuarioClienteVinculo/usuarioClienteVinculoService";
import ClienteService from "./clienteService";
import { trataDadosClientes } from "./clienteUtils";

export const MSG = {
  ERRO_OBTER_CLIENTES: "Ocorreu um erro ao tentar retornar todos os clientes.",
  ERRO_SALVAR_CLIENTE: "Ocorreu erro ao tentar salvar dados do cliente",
  ERRO_EXCLUIR_CLIENTE: "Ocorreu erro ao tentar exlcuir cliente",
  ERRO_OBTER_CLIENTE_POR_ID:
    "Ocorreu um erro ao tentar retornar dados do cliente.",
  ERRO_OBTER_HISTORICO_CLIENTE:
    "Ocorreu um erro ao tentar retornar histórico do cliente.",
  ERRO_SUSPENDER_CLIENTE: "Ocorreu erro ao tentar suspender cliente",
  ERRO_GENERICO: "Ocorreu um erro, por favor tente novamente",
};

class ClienteManager {
  async obtemClientes() {
    try {
      const respostaServicoCliente = await ClienteService.obtemClientes();
      const clientes = respostaServicoCliente.data;
      const clientesUpperCase = clientes.map((cliente) => {
        cliente.Nome = cliente.Nome.toUpperCase();
        return cliente;
      });
      const collator = new Intl.Collator("pt-BR", { sensitivity: "base" });

      const clientesOrdenados = clientesUpperCase.sort((a, b) =>
        collator.compare(a.Nome, b.Nome)
      );
      const dadosClientesTratados = trataDadosClientes(clientesOrdenados);
      return dadosClientesTratados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTES);
    }
  }

  async obtemClientesAtivos() {
    try {
      const respostaServicoCliente = await ClienteService.obtemClientesAtivos();
      const clientes = respostaServicoCliente.data;
      const clientesUpperCase = clientes.map((cliente) => {
        cliente.Nome = cliente.Nome.toUpperCase();
        return cliente;
      });
      const collator = new Intl.Collator("pt-BR", { sensitivity: "base" });

      const clientesOrdenados = clientesUpperCase.sort((a, b) =>
        collator.compare(a.Nome, b.Nome)
      );
      const dadosClientesTratados = trataDadosClientes(clientesOrdenados);
      return dadosClientesTratados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTES);
    }
  }

  async salvaCliente(IdCliente, clienteSalvar, usuariosAdministradores) {
    try {
      let respostaServicoCliente;
      if (IdCliente) {
        respostaServicoCliente = await ClienteService.salvaCliente(
          IdCliente,
          clienteSalvar
        );
      } else {
        respostaServicoCliente = await ClienteService.cadastraCliente(
          clienteSalvar
        );
      }

      if (usuariosAdministradores) {
        const clientes = await ClienteService.obtemClientePorFiltro({
          Nome: clienteSalvar.Nome,
          Excluido: false,
        });
        const idCliente = IdCliente || clientes[0].IdCliente;

        const { data: usuariosAdministradoresExistentes } =
          await UsuarioService.obtemUsuariosAdministradoresPorCliente(
            idCliente
          );

        const usuariosAdministradoresExistentesIds =
          usuariosAdministradoresExistentes.map((usuario) => usuario.IdUsuario);
        const usuariosAdministradoresParaExcluir =
          usuariosAdministradoresExistentesIds.filter(
            (id) => !usuariosAdministradores.includes(id)
          );
        const usuariosAdministradoresParaIncluir =
          usuariosAdministradores.filter(
            (id) => !usuariosAdministradoresExistentesIds.includes(id)
          );

        const usuariosAdministradoresPromises = [];
        usuariosAdministradoresParaExcluir.forEach((idUsuario) => {
          usuariosAdministradoresPromises.push(
            UsuarioService.excluiUsuarioAdministrador(idCliente, idUsuario)
          );
        });
        usuariosAdministradoresParaIncluir.forEach((idUsuario) => {
          usuariosAdministradoresPromises.push(
            UsuarioService.cadastraUsuarioAdministrador(idUsuario, idCliente)
          );
        });

        await Promise.all(usuariosAdministradoresPromises);
      }

      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_CLIENTE);
    }
  }

  async excluiCliente(IdCliente) {
    try {
      const respostaServicoCliente = await ClienteService.excluiCliente(
        IdCliente
      );
      await usuarioClienteVinculoService.excluiVinculoUsuarioPorCliente(
        IdCliente
      );
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_CLIENTE);
    }
  }

  async obtemClientePorId(IdCliente) {
    try {
      const respostaServicoCliente = await ClienteService.obtemClientePorId(
        IdCliente
      );
      return respostaServicoCliente.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTE_POR_ID);
    }
  }

  async obtemClientePorIdIncluindoExcluidos(IdCliente) {
    try {
      const respostaServicoCliente =
        await ClienteService.obtemClientePorIdIncluindoExcluidos(IdCliente);
      return respostaServicoCliente.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTE_POR_ID);
    }
  }

  async obtemHistoricoCliente(IdCliente, pagina, quantidade) {
    try {
      const respostaServicoCliente = await ClienteService.obtemHistoricoCliente(
        IdCliente,
        pagina,
        quantidade
      );
      return respostaServicoCliente.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_HISTORICO_CLIENTE);
    }
  }
  async suspendeCliente(IdCliente, motivo) {
    try {
      const respostaServicoCliente = await ClienteService.suspendeCliente(
        IdCliente,
        motivo
      );
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_GENERICO);
    }
  }

  async inativaCliente(IdCliente, motivo) {
    try {
      const respostaServicoCliente = await ClienteService.inativaCliente(
        IdCliente,
        motivo
      );
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_GENERICO);
    }
  }

  async ativaCliente(IdCliente, motivo) {
    try {
      const respostaServicoCliente = await ClienteService.ativaCliente(
        IdCliente,
        motivo
      );
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_GENERICO);
    }
  }

  async restauraAcessoCliente(IdCliente, motivo) {
    try {
      const respostaServicoCliente = await ClienteService.restauraAcessoCliente(
        IdCliente,
        motivo
      );
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_GENERICO);
    }
  }
}
export default new ClienteManager();
