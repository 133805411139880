import axios from "axios";

const URI_CLIENTE = "/clientes/api/v1/clientes/";
const URI_CLIENTE_V2 = "/clientes/api/v2/clientes/";
class ClienteService {
  async obtemClientes() {
    return axios.get(`${URI_CLIENTE_V2}`, { headers: {} });
  }

  async obtemClientesAtivos() {
    return axios.get(`${URI_CLIENTE}`, { headers: {} });
  }

  async obtemClientePorFiltro(filtro) {
    const { data: resposta } = await axios.get(`${URI_CLIENTE_V2}`);

    const respostaFiltrada = resposta.filter((cliente) => {
      for (const key in filtro) {
        if (cliente[key] !== filtro[key]) {
          return false;
        }
      }
      return true;
    });
    return respostaFiltrada;
  }

  async obtemClientePorId(IdCliente) {
    return axios.get(`${URI_CLIENTE}${IdCliente}`);
  }

  async obtemClientePorIdIncluindoExcluidos(IdCliente) {
    return axios.get(`${URI_CLIENTE}${IdCliente}?withDeleted=true`);
  }

  async cadastraCliente(clienteCadastrar) {
    return axios.post(`${URI_CLIENTE}`, clienteCadastrar);
  }

  async salvaCliente(IdCliente, clienteSalvar) {
    return axios.put(`${URI_CLIENTE}${IdCliente}`, clienteSalvar);
  }

  async suspendeCliente(IdCliente, motivo) {
    return axios.put(`${URI_CLIENTE}${IdCliente}/suspender`, {
      motivo,
    });
  }

  async excluiCliente(IdCliente) {
    return axios.delete(`${URI_CLIENTE}${IdCliente}`);
  }

  async obtemHistoricoCliente(IdCliente, pagina, quantidade) {
    return axios.get(`${URI_CLIENTE}${IdCliente}/historico`, {
      params: { page: pagina, size: quantidade },
    });
  }

  async inativaCliente(IdCliente, motivo) {
    return axios.put(`${URI_CLIENTE}${IdCliente}/inativar`, { motivo });
  }

  async ativaCliente(IdCliente, motivo) {
    return axios.put(`${URI_CLIENTE}${IdCliente}/ativar`, { motivo });
  }

  async restauraAcessoCliente(IdCliente, motivo) {
    return axios.put(`${URI_CLIENTE}${IdCliente}/restaurar-acesso`, { motivo });
  }
}
export default new ClienteService();
